const handleSearchform = () => {
    const openBtn = document.querySelector(".open-search-form");
    const closeBtn = document.querySelector(".close-search-form");
    const searchformContainer = document.querySelector(".search-form-popup-overlay");

    openBtn.addEventListener("click", e => {
        e.preventDefault();
        searchformContainer.classList.add("open");
    });

    closeBtn.addEventListener("click", e => {
        e.preventDefault();
        searchformContainer.classList.remove("open");
    })
}

export default handleSearchform;