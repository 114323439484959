const stylesArray = [
    {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#444444',
            },
        ],
    },

    {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },

    {
        featureType: 'administrative.locality',
        elementType: 'labels.text',
        stylers: [
            {
                gamma: '1.00',
            },

            {
                visibility: 'on',
            },

        ],
    },

    {
        featureType: 'administrative.neighborhood',
        elementType: 'labels.text',
        stylers: [
            {
                gamma: '4.00',
            },
        ],

    },

    {
        featureType: 'administrative.neighborhood',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9da8b1',
            },
        ],
    },

    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                color: '#f2f2f2',
            },
        ],
    },

    {
        featureType: 'landscape',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#f4f4f8',
            },
        ],
    },

    {
        featureType: 'landscape.natural.terrain',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },

    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },

    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            {
                saturation: -100,
            },

            {
                lightness: 45,
            },
        ],
    },

    {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },

    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#9da8b1',
            },

            {
                gamma: '3.00',
            },
        ],
    },

    {
        featureType: 'road.arterial',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#9da8b1',
            },

            {
                lightness: '55',
            },
        ],
    },

    {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
            {
                lightness: '0',
            },

            {
                color: '#44535e',
            },

            {
                gamma: '1.00',
            },
        ],
    },

    {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },

    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
            {
                lightness: '0',
            },

            {
                color: '#9da8b1',
            },
        ],
    },

    {
        featureType: 'road.local',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },

    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },

    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                color: '#46bcec',
            },

            {
                visibility: 'on',
            },
        ],
    },

    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },

    {
        featureType: 'water',
        elementType: 'labels',
        stylers: [
            {

                visibility: 'off',

            },
        ],
    },
];

export default stylesArray;
