/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */

import ScrollMagic from 'scrollmagic';
import { getScreenHeight, isMobile } from './helper.js';
import CoreAnimation from './animations/CoreAnimation';

export const toggleMenu = () => {
    const button = document.getElementById('toggle');
    const container = document.getElementById('site-navigation');
    const html = document.querySelector('html');

    if (button && container) {
        button.addEventListener('click', () => {
            CoreAnimation.slideToggle(
                document.querySelector('#site-navigation > div > ul'),
            );

            if (button.classList.contains('open')) {
                button.classList.remove('open');
                html.style.overflowY = 'auto';
                return;
            }

            button.classList.add('open');

            if (getScreenHeight() <= 1260) {
                html.style.overflowY = 'hidden';
            } else {
                html.style.overflowY = 'auto';
            }
        });
    }

    if (isMobile()) {
        const itemsWithSubmenus = container.querySelectorAll(".menu-item-has-children > a");
        itemsWithSubmenus.forEach(item => {
            item.addEventListener("click", e => {
                e.preventDefault();

                document.querySelectorAll("ul.sub-menu.show").forEach(el => {
                    el.classList.remove("show");
                });

                const submenu = item.closest(".menu-item").querySelector(".sub-menu");
                submenu.classList.add("show");
            })
        });
    }
};

export const closeMenu = () => {
    const links = document.querySelectorAll('.main-navigation a');
    const nav = document.getElementById('site-navigation');
    const toggle = document.getElementById('toggle');

    // links.forEach((e) => {
    //     e.addEventListener('click', () => {
    //         nav.classList.remove('open');
    //         toggle.classList.remove('open');
    //     });
    // });
};

export const addSticky = () => {
    const toggle = document.getElementById('toggle');
    const header = document.getElementById('masthead');

    toggle.addEventListener('click', () => {
        header.classList.add('sticky');
    });
};

export const handleScrollMagic = (sections) => {
    const controller = new ScrollMagic.Controller();

    sections.forEach((section) => {
        const sectionId = section.getAttribute('id');
        new ScrollMagic.Scene({
            triggerElement: `#${sectionId}`,
            triggerHook: 0.5,
            offset: 100,
            duration: section.offsetHeight
        }).setClassToggle(`.sections-menu a[href='#${sectionId}'`, 'active').addTo(controller);
    });
};
