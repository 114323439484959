/* disable es-lint */
import { Loader } from '@googlemaps/js-api-loader';
import stylesArray from './maps-styles';
import { isPhone, isMobile } from "./helper";

class handleGoogleMaps {
    constructor() {
        this.loader = new Loader({
            apiKey: 'AIzaSyDwqb4P9Dlnn-uUvkSVvqBow0frKVwmnl4',
        });
        this.map = null;
        this.defaultIcon = 'https://grafen.com/wp-content/themes/grafen/assets/images/icons/pin-bg.svg';
        this.chosenIcon = 'https://grafen.com/wp-content/themes/grafen/assets/images/icons/pin-bg-active.svg';
        this.selectedMarker = null;
        this.popupContainer = document.querySelector('.distributor-popup-container');

        this.loadMap();

        document.querySelector(".close-distributor-popup").addEventListener("click", e => {
            e.preventDefault();
            this.popupContainer.classList.remove("visible");
        })
    }

    loadMap() {
        let centerCoords = { lat: 52.0914, lng: 13.2206 }
        let zoom = 7;

        if (isMobile) {
            centerCoords = { lat: 52.0914, lng: 17.2206 }
            zoom = 6.5;
        }

        this.loader.load().then(() => {
            this.map = new google.maps.Map(
              document.getElementById('google-map'),
              {
                center: centerCoords,
                zoom: zoom,
                styles: stylesArray,
                disableDefaultUI: true,
                zoomControl: true,
                zoomControlOptions: {
                  position: google.maps.ControlPosition.RIGHT_TOP,
                },
              }
            );
            this.setMarkers();
        });
    }

    setMarkers() {
        window.distributors.forEach((distributor) => {
            const myLatLng = { lat: +distributor.lat, lng: +distributor.lng };
            const { map } = this;
            const image = this.defaultIcon;
            const marker = new google.maps.Marker({
                position: myLatLng,
                map,
                title: distributor.name,
                icon: image,
                id: distributor.id,
            });

            marker.addListener('click', () => {
                if (this.selectedMarker) {
                    this.selectedMarker.setIcon(this.defaultIcon);
                }
                marker.setIcon(this.chosenIcon);
                this.selectedMarker = marker;

                this.buildAndShowDistributorPopup(marker.id);
            });
        });
    }

    buildAndShowDistributorPopup(distributorId) {
        const distributor = window.distributors[distributorId];

        const tags = document.querySelector('.distributor-popup-container .tags');
        tags.innerHTML = '';
        distributor.tags.forEach((tag) => {
            tags.innerHTML += `<span class='tag'>${tag.label}</span>`;
        });

        const name = document.querySelector('.distributor-popup-container .name');
        name.textContent = distributor.name;

        const brands = document.querySelector('.distributor-popup-container .brands');
        brands.innerHTML = '';

        for (const [key, value] of Object.entries(distributor.brands)) {
            brands.innerHTML += `<img src='${value['brand-logo'].url}' alt='${value['brand-name']}'/>`;
        }

        const address = document.querySelector('.distributor-popup-container .address-info');
        address.innerHTML = distributor.address;

        const phone = document.querySelector('.distributor-popup-container .phone-number');
        phone.href = `tel:${distributor.phone}`;
        phone.textContent = distributor.phone;

        const cleaAddress = this.getMapLink(distributor.address);
        const mapLink = document.querySelector(".distributor-popup-container .map-link");
        mapLink.href = cleaAddress;

        this.popupContainer.classList.add("visible");

        if (isPhone()) {
            document.querySelector('.distributor-popup-container').scrollIntoView();
        }
    }

    getMapLink(address) {
        let newAddress = address.replaceAll("<br />", "+");
        newAddress = newAddress.replaceAll(" ", "+");
        return `https://www.google.pl/maps/dir//${newAddress}/`;
    }
}

export default handleGoogleMaps;
