export default class InteractiveMap {
    constructor(container) {
        this.container = container;
        this.voivodeshipContainers = this.container.querySelectorAll(".voivodeship-container");
        this.managersInfoContainer = document.querySelector(".managers-info");
        this.voivodeshipsNames = {
            "dolnoslaskie" : "dolnośląskie",
            "kujawsko-pomorskie": "kujawsko-pomorskie",
            "lubelskie" : "lubelskie",
            "lubuskie" : "lubuskie",
            "lodzkie" : "łódzkie",
            "malopolskie" : "małopolskie",
            "mazowieckie" : "mazowieckie",
            "opolskie" : "opolskie",
            "podkarpackie" : "podkarpackie",
            "podlaskie" : "podlaskie",
            "pomorskie" : "pomorskie",
            "slaskie" : "śląskie",
            "swietokrzyskie" : "świętokrzyskie",
            "warminsko-mazurskie" : "warmińsko-mazurskie",
            "wielkopolskie" : "wielkopolskie",
            "zachodniopomorskie" : "zachodniopomorskie"
        }

        this.handleEventListeners();
    }

    handleEventListeners() {
        this.container.addEventListener("click", e => {
            this.removeActiveClass();
            const parent = e.target.closest(".voivodeship-container");
            this.getManagers(parent.getAttribute("id"));
        });
    }

    getManagers(voivodeship) {
        // managers object is set in template-parts/contact/regional-sales-managers.php
        const voivodeshipManagers = managers[voivodeship];
        this.buildManagersBox(voivodeshipManagers);
        this.highlightVoivodeships(voivodeshipManagers);
    }

    buildManagersBox(managersInfo) {
        let html = "";
        managersInfo.forEach(manager => {
           html += this.buildSingleManagerBox(manager);
        });
        this.managersInfoContainer.innerHTML = html;
    }

    buildSingleManagerBox(manager) {
        const container = document.createElement("div");
        container.classList.add("single-manager");

        const voivodeships = document.createElement("p");
        voivodeships.classList.add("voivodeships");
        const voivodeships_names = this.getManagerVoivodeships(manager.voivodeships);
        voivodeships.innerText = voivodeships_names;

        const fullname = document.createElement("p");
        fullname.classList.add("fullname");
        fullname.innerText = manager.fullname;

        const phone = document.createElement("a");
        phone.classList.add("phone");
        phone.href = `tel:+48${manager.phone.split(" ").join("")}`;
        phone.innerHTML = `(+48) <strong>${manager.phone}</strong>`;

        const email = document.createElement("a");
        email.classList.add("email");
        email.href = `mailto:${manager.email}`;
        email.innerText = manager.email;

        container.appendChild(voivodeships);
        container.appendChild(fullname);
        container.appendChild(phone);
        container.appendChild(email);

        return container.outerHTML;
    }

    getManagerVoivodeships(voivodeships) {
        let result = "";
        voivodeships.forEach(item => {
           result += `${this.voivodeshipsNames[item]}, `;
        });
        return result.slice(0, -2);
    }

    highlightVoivodeships(managers) {
        let voivodeships = [];
        managers.forEach(manager => {
           voivodeships = new Set([...voivodeships, ...manager["voivodeships"]]);
        });

        voivodeships.forEach(item => {
            this.voivodeshipContainers.forEach(container => {
                if (container.getAttribute("id") === item) {
                    container.classList.add("active");
                }
            });
        });
    }

    removeActiveClass() {
        this.voivodeshipContainers.forEach(el => {
            el.classList.remove("active");
        });
    }
}