// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/** **** SLIDERS ****** */
const sliders = {
    home: $(".homepage-slider .inner"),
    technology: $(".block-technology-slider .inner"),
    modules: $(".modules-slider .inner"),
    outerNavigation: $(".outer-navigation .inner"),
};

sliders.outerNavigation.slick({
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    appendArrows: $(".slider-arrows"),
    dots: true,
    appendDots: $(".block-outer-navigation .slider-dots"),
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            arrows: false,
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});

sliders.modules.slick({
    autoplay: true,
    autoplaySpeed: 7000,
    arrows: false,
    dots: true,
    appendDots: $(".block-dedicated-modules .slider-dots"),
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});

sliders.technology.slick({
    autoplay: true,
    autoplaySpeed: 7000,
    arrows: false,
    dots: true,
    appendDots: $(".block-technology-slider .slider-dots"),
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});

sliders.home.slick({
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    appendDots: $(".slider-dots"),
    appendArrows: $(".slider-arrows"),
});
