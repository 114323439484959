import ClipboardJS from "clipboard";
import {
  isMobile,
  handleActiveClass,
  removeActiveClass,
  handleCookieBar,
} from "./helper";
import SiteHeader from "./siteHeader";
import {
  toggleMenu,
  addSticky,
  closeMenu,
  handleScrollMagic,
} from "./navigation";
import FloatLabels from "./forms/float-labels";
import InteractiveMap from "./InteractiveMap";
import faq from "./faq";
import { scrollPinningHandle } from "./animations/scroll";
import { moviePopupHandler, registrationPopupHandler } from "./popups";
import calendarHandle from "./calendar";
import handleGoogleMaps from "./maps";
import handleSearchform from "./search";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  toggleMenu();

  const googleMapContainer = document.getElementById("google-map");
  if (googleMapContainer) {
    new handleGoogleMaps();
  }

  const sectionsMenu = document.querySelectorAll(".section-anchor");
  if (sectionsMenu.length > 0) {
    handleScrollMagic(sectionsMenu);
  }

  const cookieBanner = document.querySelector(".cookie-banner");
  if (cookieBanner) {
    handleCookieBar(cookieBanner);
  }

  if (document.querySelector(".open-search-form")) {
    handleSearchform();
  }

  const copyLinkBtn = document.querySelector(".copy-link-btn");
  if (copyLinkBtn) {
    const clipboard = new ClipboardJS(copyLinkBtn);
    clipboard.on("success", (e) => {
      const tooltip = document.querySelector(".link-copied-tooltip");
      tooltip.classList.add("show");
      setTimeout(() => {
        tooltip.classList.remove("show");
      }, 1500);
    });
  }

  const interactiveMap = document.querySelector(".interactive-map");
  if (interactiveMap) {
    new InteractiveMap(interactiveMap);
  }

  const faqContainer = document.querySelector(".faq-container");
  if (faqContainer) {
    faq(faqContainer);
  }

  if (!isMobile()) {
    scrollPinningHandle();
  }

  if (document.querySelector(".movie-link")) {
    moviePopupHandler();
  }

  const calendarContainer = document.querySelector(
    ".events-calendar-container"
  );
  if (calendarContainer) {
    calendarHandle(calendarContainer);
  }

  const openRegistrationFormBtns = document.querySelectorAll(
    ".register-for-event-btn"
  );
  if (openRegistrationFormBtns.length > 0) {
    registrationPopupHandler(openRegistrationFormBtns);
  }

  const jsToggleElements = document.querySelectorAll(".js-toggle-active");
  if (jsToggleElements.length > 0) {
    jsToggleElements.forEach((el) => handleActiveClass(el));
  }

  const accordionInsideTabsContainers = document.querySelectorAll(
    ".extended-single-tab"
  );
  if (accordionInsideTabsContainers.length > 0) {
    accordionInsideTabsContainers.forEach((el) => {
      const btns = [...el.querySelectorAll(".click-element-extended")];
      const contentContainers = [
        ...el.querySelectorAll(".target-element-extended"),
      ];

      if (btns.length > 0) {
        btns.forEach((btn) => {
          btn.addEventListener("click", (e) => {
            e.preventDefault();
            removeActiveClass(btns, contentContainers);
            btn.classList.add("active");
            contentContainers[btns.indexOf(btn)].classList.add("active");
          });
        });
      }
    });
  }

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};

const body = document.querySelector("body");
const aboutVid = document.querySelector("video");
if (body.classList.contains("page-template-about") && aboutVid) {
  aboutVid.addEventListener("play", function () {
    aboutVid.muted = false;
  });
}
