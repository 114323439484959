import Cookies from "js-cookie";

export const getScreenWidth = () => {
    const w = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName('body')[0];
    const width = w.innerWidth || e.clientWidth || g.clientWidth;

    return width;
};

export const getScreenHeight = () => {
    const w = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName('body')[0];
    const height = w.innerHeight || e.clientHeight || g.clientHeight;

    return height;
};

export const isMobile = () => {
    // if (getScreenWidth() <= 770) return true;
    if (getScreenWidth() <= 1260) return true;
};

export const isPhone = () => {
    if (getScreenWidth() <= 440) return true;
};

export const getCoords = (elem) => {
    const box = elem.getBoundingClientRect();

    const { body } = document;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
};

export const removeActiveClass = (headings, contentContainers) => {
    [...headings, ...contentContainers].forEach((item) => {
        item.classList.remove('active');
    });
};

export const handleActiveClass = (container) => {
    const btns = [...container.querySelectorAll('.click-element')];
    const contentContainers = [...container.querySelectorAll('.target-element')];

    if (btns.length > 0) {
        btns.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                removeActiveClass(btns, contentContainers);
                btn.classList.add('active');
                contentContainers[btns.indexOf(btn)].classList.add('active');
            });
        });
    }
};

export const handleCookieBar = (container) => {
	const btn = container.querySelector(".accept-cookies");
	btn.addEventListener("click", (e) => {
		e.preventDefault();
		container.classList.add("hidden");
		Cookies.set("cookie-banner", "accepted");
	});
};