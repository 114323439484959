const eventsLoader = document.querySelector(".events-loader");

const performAjaxRequest = async (selects) => {
    const form = document.querySelector(".events-calendar-form form");

    const fd = new FormData();
    fd.append("action", "ajaxGetCalendar");
    selects.forEach(select => {
        fd.append(`${select.id}`, select.value);
    });
    fd.append("selected_terms", form.getAttribute("data-terms"));


    fetch(jsData.ajaxUrl, {
        method: "POST",
        body: fd
    })
        .then(response => response.json())
        .then(data => {
            const calendarWrapper = document.querySelector(".events-calendar");
            calendarWrapper.innerHTML = data.html;
            eventsLoader.classList.remove("show");
        });
}

const calendarHandle = container => {
    const selects = document.querySelectorAll("select#month, select#year");
    selects.forEach(select => {
        select.addEventListener("change", () => {
            eventsLoader.classList.add("show");
            performAjaxRequest(selects);
        });
    });
}

export default calendarHandle;