export const moviePopupHandler = () => {
   const moviePopupOverlay = document.querySelector(".movie-popup-overlay");
   const moviePopupContainer = document.querySelector(".movie-container");
   const popupOpenBtns = document.querySelectorAll(".movie-link");

   popupOpenBtns.forEach(btn => {
      btn.addEventListener("click", e => {
         e.preventDefault();
         const iframeCode = btn.getAttribute("data-frame");

         moviePopupContainer.innerHTML = iframeCode;
         moviePopupOverlay.classList.add("show");
      })
   });

   const closeBtn = document.querySelector(".close-movie-popup");
   closeBtn.addEventListener("click", e => {
      e.preventDefault();
      moviePopupContainer.innerHTML = "";
      moviePopupOverlay.classList.remove("show");
   })
}

export const registrationPopupHandler = btns => {
   const registrationFormPopup = document.querySelector(".registration-form-popup");

   btns.forEach(btn => {
      btn.addEventListener("click", e => {
         e.preventDefault();
         document.documentElement.style.overflowY = "hidden";
         registrationFormPopup.classList.add("show");

         document.querySelector(".close-registration-popup-btn").addEventListener("click", e => {
            e.preventDefault();
            document.documentElement.style.overflowY = "auto";
            registrationFormPopup.classList.remove("show");
         });
      })
   });
}