import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const scrollPinningHandle = () => {
    // if (document.querySelector(".header-scroll")) {
    //     const header = document.querySelector(".header-scroll");
    //     ScrollTrigger.create({
    //         trigger: header,
    //         start: "top top",
    //         end: "bottom 20%",
    //         onToggle: self => {
    //             if (self.isActive) {
    //                 header.classList.add("sticky");
    //             } else header.classList.remove("sticky");
    //         }
    //     });
    // }

    const header = document.getElementById("masthead");
    const triggers = document.querySelectorAll(".scroll-trigger");
    if (triggers.length) {
        triggers.forEach(trigger => {
            ScrollTrigger.create({
                trigger: trigger,
                start: "top 96px",
                end: "bottom 186px",
                onToggle: () => header.classList.toggle("shadow"),
                onUpdate: self => {
                    document.documentElement.style.setProperty("--opacity", (1-self.progress));
                },
            });
        })
    }
}